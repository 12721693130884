<template>
  <div class="hTable" id="hTable">
    <!-- <img src="@/assets/img/home/menuIcon.png" alt="" @click="handleNavIconClick" srcset=""> -->
    <div class="selectBox" id="selectTBox">
      <div>
        <slot name="selectBox" />
      </div>
      <van-popover class="htablePopover" get-container="#selectTBox" v-model="showFSetting" :actions="option" placement="bottom-end"
        @select="onSelectSetting" :close-on-click-outside="false">
        <template #reference>
          <div class="selectBoxItem" @click="showFSetting = !showFSetting">表单设置
            <img :src="$common.icon.down" alt="" srcset="">
          </div>
        </template>
        <template #default>
          <van-checkbox-group v-model="checkedList" class="checkBox">
            <van-checkbox :name="item.prop" v-for="(item, index) in option" :key="index" shape="square"
            @click="onCheckItem(item, index)">
            <template #icon="props">
              <div class="box" v-if="!props.checked"></div>
              <img v-else class="img-icon" src="@/assets/img/home/onCheck.png" />
            </template>
              {{ item.label }}</van-checkbox>
          </van-checkbox-group>
        </template>
      </van-popover>
    </div>
    <div class="tableMain">
      <el-table ref="xtable" :key="tableKey" v-loading="tableLoading" :max-height="maxHeight" :data="tableData"
        row-key="id" :size="size" tooltip-effect="light" header-row-class-name="rowHeader" stripe highlight-current-row>
        <!-- @selection-change="handleSelectionChange" @select="onSelect" @select-all="onSelectAll" -->
        <template v-for="item in option">
          <el-table-column v-if="!item.hide" :key="item.prop" :prop="item.prop" :label="item.label" :width="item.width"
            :min-width="item.minWidth || ''" :url-color="item.urlColor || ''" :align="item.align || 'center'"
            :show-overflow-tooltip="item.overHidden==false? false:true">
            <template slot-scope="scope">
              <slot v-if="item.slot" :name="scope.column.property" :row="scope.row" :$index="scope.$index" />
              <!-- 有默认数据 -->
              <span v-else-if="(item.dicData) || item.dicUrl">
                <span v-if="
                  scope.row[scope.column.property] === null ||
                  scope.row[scope.column.property] === ''
                ">/</span>
                <!-- 无tag框框 数据默认 -->
                <span :class="{
                  error:
                    (item.error || item.error == 0) &&
                    item.error == scope.row[scope.column.property],
                }" :ref="`${scope.column.property}`" v-else-if="
  !item.clickable && !item.tagShow &&
  (scope.row[scope.column.property] !== null ||
    scope.row[scope.column.property] !== '')
" :size="scope.row.size || 'small'">{{
    scope.row[scope.column.property]
    | statusFilter(item.dicData, item.dicProp)
}}</span>
                <!-- 有tag框框 -->
                <el-tag :class="{
                  error:
                    (item.error || item.error == 0) &&
                    item.error == scope.row[scope.column.property],
                }" :ref="`${scope.column.property}`" v-else-if="
  !item.clickable && item.tagShow &&
  (scope.row[scope.column.property] !== null ||
    scope.row[scope.column.property] !== '')
" :size="scope.row.size || 'small'">{{
    scope.row[scope.column.property]
    | statusFilter(item.dicData, item.dicProp)
}}</el-tag>
                <!-- 默认数据点击 -->
                <span :ref="`${scope.column.property}`"
                  :style="[{ color: item.colorList ? item.colorList[scope.row[scope.column.property]] : (item.urlColor || null) }, { cursor: item.cursorList ? item.cursorList[scope.row[scope.column.property]] : (item.cursor || null) }]"
                  v-else-if="item.clickable" @click="onViewData(item, scope.row)">{{
                      scope.row[scope.column.property]
                      | statusFilter(item.dicData, item.dicProp)
                  }}</span>
              </span>
              <!-- 数据转日期 -->
            <!-- {{$moment(Number(scope.row[scope.column.property])).format(" YYYY-MM-DD  HH:mm:SS")}} -->
            <span v-else-if="item.dateType">{{
              scope.row[scope.column.property] &&
              scope.row[scope.column.property] != 0
                ? $way.timestampReturnDate(Number(scope.row[scope.column.property]),item.dateType||'timeYMDHM'): "/"
            }}</span>
              <span v-else>{{ scope.row[scope.column.property] }}</span>
            </template>
          </el-table-column>
        </template>
        <el-table-column v-if="showOpertaion" label="操作" align="center" :min-width="menuWidth" :fixed="menuFixed">
          <template slot-scope="scope">
            <slot name="menu" :row="scope.row" :$index="scope.$index" />
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="tableFoot">
      <div class="btn">
        <van-button size="small" class="first" @click="pageChange('first')">首页</van-button>
        <van-button size="small" class="last" @click="pageChange('last')">上一页</van-button>
        <van-button size="small" class="next" @click="pageChange('next')">下一页</van-button>
      </div>
      <div class="text">
        <span class="total">共{{ page.total }}条信息</span>
        <span class="totalPage">{{ page.current }}/{{ totalPage }}页</span>
        <span class="size">每页显示
          <div class="pageSize" @click="showPageSize = !showPageSize">
          <van-popover get-container="#selectTBox" v-model="showPageSize" :actions="pageSizeList" placement="top"
        @select="pageChange">
        <template #reference>
          {{ page.size }}<img :src="$common.icon.down" alt="" srcset=""></img>
        </template>
      </van-popover>
        </div>条
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'hTable',
  props: {
    tableOptionWidth: {
      type: Number,
      default: function () {
        return 100;
      },
    },
    size: {
      type: String,
      default: function () {
        return "mini";
      },
    },
    selection: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    number: {
      // 序号显隐
      type: Boolean,
      default: function () {
        return false;
      },
    },
    addBtn: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    maxHeight: {
      type: String,
      default: function () {
        return "auto";
      },
    },
    menuWidth: {
      type: [String, Number],
      default: function () {
        return "100";
      },
    },
    menuFixed: {
      type: String,
      default: function () {
        return "right";
      },
    },
    // 表格操作显隐
    showOpertaion: {
      type: Boolean,
      default: true,
    },
    showPage: {
      // 分页器显隐
      type: Boolean,
      default: function () {
        return true;
      },
    },
    tableKey: {
      type: Number,
      default: function () {
        return 0;
      },
    },
    tableLoading: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    tableData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    tableOption: {
      type: Array,
      default: function () {
        return [];
      },
    },
    page: {
      type: Object,
      default: function () {
        return {
          total: 0,
          current: 1,
          page: 10,
        };
      },
    }
  },
  filters: {
    // dicData默认数据存在。默认数据过滤处理
    statusFilter(status, list, prop = { value: "value", label: "label" }) {
      let result;
      if (Object.prototype.toString.call(list) == '[object Object]') {
        // 默认数据是对象
        return list[status]
      } else {
        // 默认数据是数组
        for (const i in list) {
          const item = list[i];
          if (status === item[prop.value]) {
            result = item[prop.label];
          } else if (
            typeof status == "number" &&
            status.toString() === item[prop.value]
          ) {
            result = item[prop.label];
          } else if (
            typeof status == "string" &&
            Number(status) === item[prop.value]
          ) {
            result = item[prop.label];
          }
        }
        return result;
      }
    },
  },
  watch:{
    checkedList:{
      immediate:true,
      handler(newV,oldV) {
        console.log(newV,'newV');
      }
    }
  },
  computed: {
    // ...mapGetters(['permissions']),
    option: function () {
      return this.tableOption.filter((item) => {
        if (item.permissionKey) {
          return this.permissions[item.permissionKey] !== false;
        } else {
          return true
        }
      });
    },
    checkedList: {
      get() {
        return this.option.map((item) => {
          if (!item.hide) {
            return item.prop
          } else {
            return null
          }
        })
      },
      set() {},
    },
    totalPage: function () {
      let a = this.page.total / this.page.size
      let b = this.page.total % this.page.size
      console.log(a, 'a', b);
      if (b !== 0) {
        return parseInt(a + 1)
      } else {
        return a
      }
    },
  },
  data() {
    return {
      // checkedList: [],
      showPageSize: false,
      showFSetting: false,
      showBatch: false,
      form: {},
      // page: {
      //   current: 1,
      //   page: 10,
      //   total: 0,
      // },
      batchList: [
        { text: '首页', moduleName: '首页', goto: 'home' },
      ],
      pageSizeList:[
        { text: '10', },
        { text: '20', },
        { text: '30', },
        { text: '50', },
      ],
      thList: [],
      tableList: [],
      OSHList: []
    }
  },
  created() {
    this.thList = this.tableOption
    console.log(this.$refs, 'this.$refs')
    console.log(this.checkedList, 'checkedList')
  },
  mounted() {
    // this.$nextTick(()=>{
    //   this.thList = this.thList.map((item,index)=>{
    //     return {
    //       width: this.$refs[item.prop][0].clientWidth,
    //       ...item
    //     }
    //   })
    //   console.log(this.thList,'thList');

    // })
  },
  methods: {
    // 复选框点击
    onCheckItem(item, index) {
      console.log(item, index, this.checkedList);
      let obj = {
        index: index,
        item: item,
      }
      console.log(this.option, 'option');
      if (item.hide !== null) {
        console.log('object');
        // this.option[index].hide = !this.option[index].hide
        this.$set(this.option[index],'hide',!this.option[index].hide)
        console.log(this.option[index],'ii');
      } else {
        // this.option[index].hide = false
        this.$set(this.option[index],'hide',false)
      }
      console.log(this.checkedList, 'checkedList');
      this.$emit('onCheckItem', obj)
    },
    pageChange(str) {
      console.log(str);
      if (this.page.total > this.page.size) {
        let val = this.page
        if (str == 'first') {
          val.current = 1
          this.$emit("pageChange", val);
        } else if (str == 'last') {
          if (this.page.current > 1) {
            val.current = this.page.current - 1
          }
          this.$emit("pageChange", val);
        } else if (str == 'next' && this.page.current<this.totalPage) {
          val.current = this.page.current + 1
          // if (this.page.total/this.page.size >1) {
          // }
          this.$emit("pageChange", val);
        } else if (str.text) {
          val.size = str.text
          // if (this.page.total/this.page.size >1) {
          // }
          this.$emit("pageChange", val);
        }
        console.log(val, 'val');
      }
    },
    onSelectSetting() {
      console.log("object");
    }
  },
}
</script>
<style lang="scss" scoped>
div#hTable.hTable {
  width: 100%;

  undefined {}

  div#selectTBox.selectBox {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      slot {}
    }
    ::v-deep .van-popup {
      overflow: auto;
      .van-popover__content {
        // height: 216px;
        // overflow: scroll !important;
        // -webkit-overflow-scrolling: touch
        .van-popover__action {
          height: auto;
          max-width: 135px;
          padding: 0 14px;
          .van-popover__action-text {
            padding: 10px 0;
          }
        }
      }
    }
    .van-popover__wrapper {
      margin: 12px 16px 12px 0;

      .selectBoxItem {
        background: #F9F9F9;
        border-radius: 15px;
        padding: 5px 10px;

        img {
          width: 13px;
          height: 8px;
          margin-left: 6px;
        }
      }
    }

    ::v-deep .van-checkbox-group {
      margin: 0 14px;
    //   max-height: 216px;
    // overflow-y: scroll !important;
    // overflow: auto;
      .van-checkbox {
        padding: 10px 0;
        border-bottom: 1px solid #EFEFEF;

        &:last-child {
          border-bottom-color: #FFFFFF;
        }
        .van-checkbox__icon {
          display: flex;
    align-items: center;
          .box{
            border-radius: 2px;
            border: 1px solid #979797;
            width: 10px;
            height: 10px;
          }
          img {
            width: 12px;
            height: 12px;
          }
        }
        .van-checkbox__label {
          min-width: 72px;
        }
      }
    }
  }

  .tableMain {
    // background: #EFEFEF;
    display: flex;
    justify-content: space-between;
    .el-table {
      .el-table__header-wrapper {
        overflow: hidden;
      }
    }
    ::v-deep .rowHeader {
      background: #EFEFEF;

      .el-table__cell {
        background: #EFEFEF;
        padding: 12px 0;

        & .cell {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 16px;
        }
      }
    }
  }

  .tableFoot {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    padding: 16px 0 29px 0;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 14px;

    .btn {
      margin-bottom: 21px;

      .van-button {
        width: 76px;
        height: 28px;
        border-radius: 6px;
        margin-left: 16px;
        border-color: #1A7EE2;

        &.first {
          color: #1A7EE2;
          margin-left: 0;
        }

        &.last,
        &.next {
          background: #1A7EE2;
          color: #FFFFFF;
        }
      }
    }

    .text {
      display: flex;
      align-items: center;
      .size {
        display: flex;
        align-items: center;
        .pageSize {
          padding: 5px;
          margin: 0 5px;
    background: #F9F9F9;
    border-radius: 4px;
    display: flex;
    align-items: center;
    img {
      width: 13px;
    height: 8px;
    margin-left: 6px;
    }
        }
      }

      .totalPage {
        margin: 0 10px;
      }
    }
  }
}
</style>