<template>
  <div class="liveManage">
    <div class="time">
      2022上学期 2022.01.01–2
    </div>
    <div class="searchBox">
      <form action="/">
        <van-search
          v-model="form.searchField"
          show-action
          shape="round"
          placeholder="姓名、学号、身份证号、账号"
          action-text="搜索"
          @search="getTableList"
        >
        <template #action>
          <div @click="getTableList">搜索</div>
        </template>
      </van-search>
      </form>
    </div>
    <!-- <img src="@/assets/img/home/menuIcon.png" alt="" @click="handleNavIconClick" srcset=""> -->
    <hTable
      ref="hTable"
      :tableOption="tableOption"
      :tableData="tableData"
      :page="page"
      @pageChange="onPageChange"
      >
      <template slot="selectBox">
        <van-popover class="selectPopver" get-container="#selectBox"  v-model="showBatch" :actions="batchList" placement="bottom-start" @select="onSearch">
          <template #reference>
            <div class="selectBoxItem" @click="showBatch=!showBatch">所有批次</div>
            <img :src="$common.icon.down" alt="" srcset="">
          </template>
        </van-popover>
      </template>
      <template slot="menu" slot-scope="scope">
        <span class="blue" size="mini" @click="onReset(scope.row)">重置密码</span>
      </template>
    </hTable>
    <!-- <Xtable ref="xtabel" class="table-area"   :table-key="tableKey" :table-loading="tableLoading"
        :table-data="tableData" :page="page" :showMenu="true" :table-option.sync="tableOption"
        @page-change="getTableList" >
    </Xtable> -->
    <div class="main">
    </div>
  </div>
</template>

<script>
import hTable from '@/components/commonModule/hTable/index'
export default {
  name: 'liveManage',
  props: [],
  components: {
    hTable,
  },
  data() {
    return {
      showFSetting:false,
      showBatch:false,
      form:{},
      batchList:[
        // { text: '首页', moduleName: '首页', goto: 'home' },
      ],
      tableKey: 0,
      tableLoading: false,
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      tableData: [],
      tableOption: [
        {
          prop: "name",
          label: "学生姓名",
          width:100
        },
        {
          prop: "examNum",
          label: "考生号",
          width:150
        },
        {
          label: '学号',
          prop: 'studentNum',
        },
        {
          label: '批次',
          prop: 'admissionBatch',
        },
        {
          prop: "batch",
          label: "批次",
          width:65,
          hide:true
        },
        // {
        //   prop: "eduCategory",
        //   label: "层次",
        //   width:65,
        //   dicData: this.$common.eduCategorys
        // },
        // {
        //   prop: "majorName",
        //   label: "专业",
        // },
        // {
        //   prop: "stationName",
        //   label: "教学点",
        // },
        // {
        //   prop: "studentStatus",
        //   label: "学籍状态",
        //   width:80,
        //   dicData: this.studentStatus
        // },
        // {
        //   prop: "frontStatus",
        //   label: "前置学历清查状态",
        //   width:150,
        //   cursor: 'pointer',
        //   colorList: {
        //     0: '#F64E60',
        //     1: '#FFA81E',
        //     2: '#1A7EE2',
        //     3: '#F64E60',
        //   },
        //   dicData: this.$common.educationalCheck,
        //   clickable: true,
        //   view: this.handleRowClick
        // },
        // {
        //   prop: "isFront",
        //   label: "前置学历是否查清",
        //   width:150,
        //   dicData: this.$common.commonStatus,
        // },
      ],
    }
  },
  created() {
    this.getAdmissionBatch()
    this.getTableList()
  },
  methods: {
    onSearch() {
      console.log("object")
    },
    onSelectSetting() {
      console.log("object")
    },
    onPageChange(val) {
      this.page = val
      console.log(this.page,'page');
      this.getTableList()
    },
    getTableList() {
      this.tableLoading = true
      this.$request.getSysstudenPage(
        Object.assign(
          {
            currentPage: this.page.current,
            pageSize: this.page.size
          },
          this.form
        )).then((res) => {
        if (res.data.code == 0) {
          // console.log(this.$refs.hTable);
          let { records, current, size, total } = res.data.data;
          this.tableData = records;
          this.page = {
            current,
            size,
            total,
          };
          this.tableLoading = false
        }
      }).catch((err) => {
        this.tableLoading = false
      })
    },
    // 获取入学批次数据
    getAdmissionBatch() {
      // if(this.schoolBatchData.length != 0) return;
      // let obj = {
      //   type: "admission_batch_type",
      // };
      this.$request.getGoSchool().then((res) => {
        if (res.data.code == 0) {
          this.batchList = res.data.data.map((item)=>{
            return {
              ...item,
              text: item.label
            }
          });
          console.log(this.batchList);
        }
      });
    },
    // 重置密码
    onReset (row) {
      console.log(row);
      this.$request.resetScpassword({ loginNum: row.loginNum }).then((res) => {
        if (res.data.code == 0) {
          // this.Success(res.data.data);
        }
        this.rowData = {};
        // this.isShowPass = !this.isShowPass;
      });
    }
  },
}
</script>

<style lang='scss' scoped>
.blue {
  color: #1A7EE2;
}
.liveManage {
  .time {
    background: #F9F9F9;
    padding: 14px 16px;
    font-size: 12px;
    font-family: ArialMT;
    color: #3B3B3B;
    line-height: 16px;
  }
  .header {
    width: 100%;
    background: url("../../assets/img/home/login-h.png") no-repeat;
    background-size: contain;
    font-size: 26px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 26px;
    background-size: contain;
    padding: 147px 40px 87px;
    div {

    }
  }
.searchBox {
  padding: 7px 16px;
  ::v-deep .van-search {
    padding: 0;
    .van-search__content {
      border-radius:100px 0px 0px 100px;
    }
    .van-search__action {
      font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
background: #1A7EE2;
border-radius: 0px 100px 100px 0px;
    }
  }
}
.hTable {
  .selectPopver {
    margin: 12px 0 12px 16px !important;
    background: #F9F9F9;
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    img {
      width: 13px;
      height: 8px;
      margin-left: 6px;
    }
  }
}
  .main {
    border-radius: 20px 20px 0px 0px;
    position: relative;
    top: -33px;
    background: #FFFFFF;
  }
}
</style>